<template>
  <v-container v-if="showSummary">
    <h2>Adjustment Processing Summary</h2>
    <p class="mt-3">
      Your file was uploaded and processed. The following is a summary. For more details, you can visit the Upload
      History tab.
    </p>
    <ul class="mb-6">
      <li>
        <strong>Batch ID:</strong>
        {{ adjustmentResult.batchId }}
      </li>
      <li><strong>Total records:</strong> {{ adjustmentResult.totalCount }}</li>
      <li><strong>Completed:</strong> {{ adjustmentResult.completedCount }}</li>
      <li><strong>Failed:</strong> {{ adjustmentResult.failedCount }}</li>
    </ul>
    <v-btn color="primary" @click="onStartOver">
      Upload More Adjustments
    </v-btn>
  </v-container>
  <v-container v-else>
    <ApiError :errors="errors"></ApiError>
    <div class="text-center pb-5" v-if="isUploading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <p>
          Please download the CSV template and populate the template with Adjustments for a
          {{ $i18n.translate("Participant") }} or {{ $i18n.translate("Organization") }}.
        </p>

        <v-btn class="mt-0 mb-5" small @click="downloadTemplate()">
          <v-icon>mdi-download</v-icon> Download CSV Template
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <p>
          Upload the CSV template of Adjustments:
        </p>

        <UploadField
          outlined
          dense
          label="Upload Adjustment File"
          v-model="ajustmentUploads"
          :allowedfileExtensions="['csv', 'xlsx']"
          :limitNumberFile="1"
          @change="handleFileUpload"
          :disabled="showSummary"
          class="required"
          :rules="rules.uploadField"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <p>
          Select the Award Vehicle to use for Adjustments:
        </p>
        <AwardVehicleField
          outlined
          dense
          v-model="selectedAwardVehicle"
          label="Select Award Vehicle"
          :disabled="!fileProcessed"
          class="required"
          :rules="rules.awardVehicle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <v-btn color="primary" @click="processAdjustments" :disabled="!selectedAwardVehicle || !parsedRecords.length">
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadField from "../../fields/UploadField.vue";
import ApiService from "../../../services/api.service";
import AwardVehicleField from "../../fields/AwardVehicleField.vue";
import ApiError from "../../display/ApiError.vue";

export default {
  components: {
    UploadField,
    AwardVehicleField,
    ApiError
  },
  name: "BulkAdjustment",
  data() {
    return {
      isUploading: false,
      result: null,
      fileProcessed: false,
      ajustmentUploads: null,
      selectedAwardVehicle: null,
      parsedRecords: [],
      showSummary: false,
      adjustmentResult: {
        batchId: "",
        completedCount: 0,
        failedCount: 0,
        totalCount: 0
      },
      completedCount: 0,
      errors: {},
      failedCount: 0,
      resultHeaders: [
        { text: "Batch ID", value: "batchId" },
        { text: "Participant Key", value: "participantKey" },
        { text: "Organization Key", value: "organizationKey" },
        { text: "Payout Amount", value: "payoutAmount" },
        { text: "Payout Message", value: "payoutMessage" },
        { text: "Status", value: "status" },
        { text: "Error Message", value: "errorMessage" }
      ],
      rules: {
        awardVehicle: [v => !!v || "An award vehicle is required."],
        uploadField: [v => !!v || "Field is required"]
      }
    };
  },
  methods: {
    onStartOver() {
      this.isUploading = false;
      this.result = null;
      this.fileProcessed = false;
      this.ajustmentUploads = null;
      this.selectedAwardVehicle = null;
      this.parsedRecords = [];
      this.showSummary = false;
      this.adjustmentResult = {
        batchId: "",
        completedCount: 0,
        failedCount: 0,
        totalCount: 0
      };
      this.completedCount = 0;
      this.errors = {};
      this.failedCount = 0;
    },
    downloadTemplate() {
      const csvContent = "SalesPersonId,DealerNumber,PayoutAmount,PayoutMessage\n";
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async processAdjustments() {
      if (!this.selectedAwardVehicle || !this.parsedRecords.length) return;
      this.isUploading = true;

      const postForm = {
        awardVehicle: { id: this.selectedAwardVehicle.id },
        payoutAdjustments: this.parsedRecords.map(record => ({
          participantKey: record.SalesPersonId,
          organizationKey: record.DealerNumber,
          payoutAmount: record.PayoutAmount,
          payoutMessage: record.PayoutMessage
        }))
      };

      try {
        const response = await ApiService.post("/api/payoutLoadBatches/process", postForm);
        if (response.data.length > 0) {
          const searchForm = { batchId: response.data[0].batchId };
          const summaryByBatchIdResponse = await ApiService.post(
            "/api/payoutLoadBatches/search/group/byBatchId",
            searchForm
          );
          this.adjustmentResult.batchId = summaryByBatchIdResponse.data[0].batchId;
          this.adjustmentResult.completedCount = summaryByBatchIdResponse.data[0].completedCount;
          this.adjustmentResult.failedCount = summaryByBatchIdResponse.data[0].failedCount;
          this.adjustmentResult.totalCount = summaryByBatchIdResponse.data[0].totalCount;
          this.showSummary = true;
        }
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
      } finally {
        this.isUploading = false;
      }
    },
    handleFileUpload(event) {
      if (!event) {
        return;
      }
      const file = event;
      const reader = new FileReader();
      reader.onload = e => {
        const text = e.target.result;
        this.parseCSV(text);
      };
      reader.readAsText(file);
    },
    parseCSV(text) {
      try {
        const lines = text.split("\n");
        const result = [];
        for (let i = 1; i < lines.length; i++) {
          const values = lines[i].split(",");
          if (values.length >= 3) {
            result.push({
              SalesPersonId: values[0].trim() || null,
              DealerNumber: values[1].trim() || null,
              PayoutAmount: parseFloat(values[2].trim()),
              PayoutMessage: values[3].trim() || null
            });
          }
        }
        this.parsedRecords = result;
        this.fileProcessed = result.length > 0;
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
      }
    }
  }
};
</script>
