<template>
  <v-combobox
    :value="selectedItem"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    hide-no-data
    item-value="id"
    :item-text="render"
    clearable
    return-object
    no-filter
    ref="combobox"
    v-if="reload"
  >
    <template v-slot:selection="{ item }">
      <slot name="selectedItemDescription" :item="item"> {{ render(item) }}</slot>
    </template>

    <template v-slot:item="{ item }">
      <slot name="itemDescription" :item="item">
        {{ render(item) }}
      </slot>
    </template>

    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            Type to filter more records...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item></template
    >
  </v-combobox>
</template>

<script>
import UtilService from "@/gapp-components/services/util.service";
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  name: "SaleField",
  props: {
    value: [Object, String],
    preventIds: Array,
    typeKey: String,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    status: { type: String, required: false }
  },
  data: () => ({
    showBottomMessage: true,
    selectedItem: {},
    loading: false,
    search: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ],
    reload: true
  }),
  methods: {
    render(item) {
      let returnValue = item.saleKey;

      if (item.saleType) {
        returnValue += " (" + item.saleType.description + ")";
      }

      if (item.saleDate) {
        returnValue += " (" + UtilService.formatDateClient(item.saleDate, "MM/DD/YYYY", this.selectedClient) + ")";
      }

      return returnValue;
    },
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    }
  },
  watch: {
    value(val) {
      this.selectedItem = val;
    },
    search(val) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = {};
      filters.keyword = val;
      filters.isEffective = this.status;

      ApiService.post("/api/sales/search", filters).then(({ data }) => {
        let fullItems = data.content;
        if (this.preventIds && this.preventIds.length > 0) {
          fullItems = fullItems.reduce((acc, cur) => {
            if (this.preventIds.indexOf(cur.id) == -1) {
              acc.push(cur);
            }
            return acc;
          }, []);
        }
        if (fullItems.length > 9) {
          this.items = fullItems.slice(0, 9);
          this.showBottomMessage = true;
        } else {
          this.items = fullItems;
          this.showBottomMessage = false;
        }
        this.loading = false;
      });
    }
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  mounted() {
    this.selectedItem = this.value;
    this.search = "";
  }
};
</script>
