<template>
  <v-card light>
    <v-toolbar flat>
      <v-toolbar-title class="headline">
        Upload History
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
    </v-toolbar>

    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <v-data-table :headers="headers" :items="files" class="elevation-1" @update:options="getData">
        <template v-slot:item.view="{ item }">
          <v-btn small @click="viewBulkAdjustmentDetail(item)">View</v-btn>
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.date | formatDateClient("MM/DD/YYYY hh:mm:ss a", selectedClient) }}
        </template>
      </v-data-table>
      <v-dialog v-model="dialog">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Viewing Upload Details for Batch Id {{ batchId }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <BulkAdjustmentDetail class="mt-5" :batchId="batchId" v-if="batchId" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import BulkAdjustmentDetail from "./BulkAdjustmentDetail.vue";
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";
import ApiError from "../../display/ApiError.vue";

export default {
  name: "BulkAdjustmentHistory",
  components: {
    ApiError,
    BulkAdjustmentDetail
  },
  data() {
    return {
      dialog: false,
      batchId: null,
      search: "",

      errors: {},
      headers: [
        { text: "View", value: "view", sortable: false },
        { text: "Batch Id", value: "batchId", sortable: false },
        { text: "Completed", value: "completedCount", align: "right", sortable: true },
        { text: "Failed", value: "failedCount", align: "right", sortable: true },
        { text: "Pending", value: "pendingCount", align: "right", sortable: true },
        { text: "Total", value: "totalCount", align: "right", sortable: true },
        { text: "Date", value: "date", sortable: true }
      ],

      files: [],

      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["createdDate"],
        sortDesc: [true]
      }
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  methods: {
    viewBulkAdjustmentDetail(item) {
      this.batchId = item.batchId;
      this.dialog = true;
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },

    onClear() {
      this.search = "";
      this.getData();
    },

    async getData() {
      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      try {
        const response = await ApiService.post(`/api/payoutLoadBatches/search/group/byBatchId`, filters);
        this.files = response.data;
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
      }
    }
  }
};
</script>
