<template>
  <div>
    <div v-if="approval">
      <h2 class="mt-3 mb-3">Approval Overview</h2>
      <ul>
        <li v-if="approval.id">
          <b>Created</b> on
          {{ approval.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
          <span v-if="submittedByParticipant">
            {{ submittedByParticipant.fullName }}
          </span>
        </li>
        <li v-if="approval.id">
          <b>Approval ID</b> is
          <router-link
            v-if="$privilege.hasPrivilege('APPROVAL_READ')"
            :to="{ name: 'approval', params: { id: approval.id } }"
            >{{ approval.approvalKey }}
          </router-link>
          <span v-else>{{ approval.approvalKey }}</span>
        </li>
        <li v-if="approvalType"><b>Approval Type</b> is {{ approvalType.name }}</li>
        <li v-if="approval.id"><b>Approval Name</b> is {{ approval.name }}</li>
        <li v-if="approval.id && approvalStage"><b>Stage</b> is {{ approvalStage.name }}</li>
        <li v-if="approval.id && assignedToParticipant"><b>Assigned</b> to {{ assignedToParticipant.fullName }}</li>
        <li v-if="approval.approved">
          <b>Approved</b> on
          {{ approval.approvedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
          <span v-if="approvedUser"> by {{ approvedUser.fullName }}</span>
        </li>
        <li v-if="approval.rejected">
          <b>Rejected</b> on
          {{ approval.rejectedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
          <span v-if="rejectedUser"> by {{ rejectedUser.fullName }}</span>
          <span v-if="approval.rejectedReason"> because &quot;{{ approval.rejectedReason }}&quot;</span>
        </li>
        <li v-if="approval.closed">
          <b>Closed</b> on
          {{ approval.closedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
          <span v-if="approvedUser"> by {{ approvedUser.fullName }}</span>
          <span v-else-if="rejectedUser"> by {{ rejectedUser.fullName }}</span>
        </li>
      </ul>
    </div>

    <div v-if="payout">
      <h2 class="mt-3 mb-3">Payout Overview</h2>
      <ul>
        <li v-if="payout.id">
          <b>Created</b> on {{ payout.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </li>
        <li v-if="payout.id">
          <b>Payout ID</b> is
          <router-link v-if="$privilege.hasPrivilege('PAYOUT_READ')" :to="{ name: 'payout', params: { id: payout.id } }"
            >{{ payout.payoutKey }}
          </router-link>
          <span v-else>{{ payout.payoutKey }}</span>
        </li>
        <li v-if="payout.payoutStrategyType"><b>Payout Strategy</b> is {{ payout.payoutStrategyType.description }}</li>
        <li v-if="payout.payoutType"><b>Payout Type</b> is {{ payout.payoutType.description }}</li>
        <li v-if="payout.awardVehicleName"><b>Payout Award Vehicle</b> is {{ payout.awardVehicleName }}</li>
        <li v-if="payout.awardVehicleDetermination">
          <b>Payout Determination</b> is {{ payout.awardVehicleDetermination.description }}
        </li>
        <li v-if="payout.payoutAmount">
          <b>Payout Amount</b> is {{ payout.payoutAmount | toCurrency }} {{ payout.currency.name }}
        </li>
        <li v-if="payout">
          <b>Payout</b> is
          <span v-if="payout.paid">paid</span>
          <span v-else>not paid</span>
        </li>
      </ul>
    </div>

    <div v-if="sale">
      <h2 class="mt-3 mb-3">Sale Overview</h2>
      <ul>
        <li v-if="sale.id">
          <b>Created</b> on {{ sale.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </li>
        <li v-if="saleType">
          <b>{{ $i18n.translate("Sale Type") }}</b> is {{ saleType.name }} (<router-link
            v-if="$privilege.hasPrivilege('SALE_TYPE_READ')"
            :to="{ name: 'saleType', params: { id: saleType.id } }"
            >{{ saleType.saleTypeKey }}</router-link
          ><span v-else>{{ saleType.saleTypeKey }}</span
          >)
        </li>
        <li>
          <b>{{ $i18n.translate("Sale Number") }}</b> is
          <router-link v-if="$privilege.hasPrivilege('SALE_READ')" :to="{ name: 'sale', params: { id: sale.id } }"
            >{{ sale.saleKey }}
          </router-link>
          <span v-else>{{ sale.saleKey }}</span>
        </li>
        <li>
          <b>{{ $i18n.translate("Sale Date") }}</b> is
          {{ sale.saleDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </li>
        <li v-if="saleType && saleType.allowSaleAmount && sale.saleAmount">
          <b>{{ $i18n.translate("Sale Amount") }}</b> is {{ sale.saleAmount | toCurrency }} {{ sale.currency.name }}
        </li>
        <li>
          <b>Customer</b> is
          <span v-if="sale.firstName || sale.middleName || sale.lastName">
            {{ sale.firstName ? sale.firstName : "" }}
            {{ sale.middleName ? sale.middleName : "" }}
            {{ sale.lastName ? sale.lastName : "" }}
          </span>
          <span v-if="sale.companyName"> from {{ sale.companyName ? sale.companyName : "" }} </span>
          <span v-if="sale.address"> from {{ sale.address.fullAddress }} </span>
        </li>
        <li v-if="saleParticipant">
          <b>{{ $i18n.translate("Participant") }}</b> is {{ saleParticipant.fullName }} (<router-link
            v-if="$privilege.hasPrivilege('PARTICIPANT_READ')"
            :to="{ name: 'participant', params: { id: saleParticipant.id } }"
            >{{ saleParticipant.participantKey }}</router-link
          ><span v-else>{{ saleParticipant.participantKey }}</span
          >)
        </li>
        <li v-if="saleOrganization">
          <b>{{ $i18n.translate("Organization") }}</b> is {{ saleOrganization.name }} (<router-link
            v-if="$privilege.hasPrivilege('ORGANIZATION_READ')"
            :to="{ name: 'organization', params: { id: saleParticipant.id } }"
            >{{ saleOrganization.organizationKey }}</router-link
          ><span v-else>{{ saleOrganization.organizationKey }}</span
          >)
        </li>

        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 1">
          <b>{{ selectedProgram.saleCustomFields[0].name }}</b
          >: {{ sale.customFieldValue1 | customField(selectedProgram.saleCustomFields[0]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 2">
          <b>{{ selectedProgram.saleCustomFields[1].name }}</b
          >: {{ sale.customFieldValue2 | customField(selectedProgram.saleCustomFields[1]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 3">
          <b>{{ selectedProgram.saleCustomFields[2].name }}</b
          >: {{ sale.customFieldValue3 | customField(selectedProgram.saleCustomFields[2]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 4">
          <b>{{ selectedProgram.saleCustomFields[3].name }}</b
          >: {{ sale.customFieldValue4 | customField(selectedProgram.saleCustomFields[3]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 5">
          <b>{{ selectedProgram.saleCustomFields[4].name }}</b
          >: {{ sale.customFieldValue5 | customField(selectedProgram.saleCustomFields[4]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 6">
          <b>{{ selectedProgram.saleCustomFields[5].name }}</b
          >: {{ sale.customFieldValue6 | customField(selectedProgram.saleCustomFields[5]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 7">
          <b>{{ selectedProgram.saleCustomFields[6].name }}</b
          >: {{ sale.customFieldValue7 | customField(selectedProgram.saleCustomFields[6]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 8">
          <b>{{ selectedProgram.saleCustomFields[7].name }}</b
          >: {{ sale.customFieldValue8 | customField(selectedProgram.saleCustomFields[7]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 9">
          <b>{{ selectedProgram.saleCustomFields[8].name }}</b
          >: {{ sale.customFieldValue9 | customField(selectedProgram.saleCustomFields[8]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 10">
          <b>{{ selectedProgram.saleCustomFields[9].name }}</b
          >: {{ sale.customFieldValue10 | customField(selectedProgram.saleCustomFields[9]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 11">
          <b>{{ selectedProgram.saleCustomFields[10].name }}</b
          >: {{ sale.customFieldValue11 | customField(selectedProgram.saleCustomFields[10]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 12">
          <b>{{ selectedProgram.saleCustomFields[11].name }}</b
          >: {{ sale.customFieldValue12 | customField(selectedProgram.saleCustomFields[11]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 13">
          <b>{{ selectedProgram.saleCustomFields[12].name }}</b
          >: {{ sale.customFieldValue13 | customField(selectedProgram.saleCustomFields[12]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 14">
          <b>{{ selectedProgram.saleCustomFields[13].name }}</b
          >: {{ sale.customFieldValue14 | customField(selectedProgram.saleCustomFields[13]) }}<br />
        </li>
        <li v-if="sale && selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 15">
          <b>{{ selectedProgram.saleCustomFields[14].name }}</b
          >: {{ sale.customFieldValue15 | customField(selectedProgram.saleCustomFields[14]) }}<br />
        </li>
      </ul>

      <div v-if="saleProducts">
        <template v-for="(saleProduct, index) in saleProducts">
          <div :key="saleProduct.id">
            <h2 class="mt-3 mb-3">Sale Product {{ index + 1 }} Overview</h2>
            <ul>
              <li>
                <b>{{ $i18n.translate("Serial Number") }}</b> is {{ saleProduct.serialNumber }}
              </li>
              <li><b>Quantity</b> is {{ saleProduct.quantity }}</li>
              <li v-if="saleProduct.product">
                <b>Product Key</b> is
                <router-link
                  v-if="$privilege.hasPrivilege('PRODUCT_READ')"
                  :to="{ name: 'product', params: { id: saleProduct.product.id } }"
                  >{{ saleProduct.product.productKey }}
                </router-link>
                <span v-else>{{ saleProduct.product.productKey }}</span>
              </li>
              <li v-if="saleProduct.product"><b>Product Name</b> is {{ saleProduct.product.name }}</li>

              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 1
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[0].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue1
                    | customField(selectedProgram.programGroup.productCustomFields[0])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 2
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[1].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue2
                    | customField(selectedProgram.programGroup.productCustomFields[1])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 3
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[2].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue3
                    | customField(selectedProgram.programGroup.productCustomFields[2])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 4
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[3].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue4
                    | customField(selectedProgram.programGroup.productCustomFields[3])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 5
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[4].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue5
                    | customField(selectedProgram.programGroup.productCustomFields[4])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 6
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[5].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue6
                    | customField(selectedProgram.programGroup.productCustomFields[5])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 7
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[6].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue7
                    | customField(selectedProgram.programGroup.productCustomFields[6])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 8
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[7].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue8
                    | customField(selectedProgram.programGroup.productCustomFields[7])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 9
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[8].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue9
                    | customField(selectedProgram.programGroup.productCustomFields[8])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    saleProduct.product &&
                    selectedProgram.programGroup.productCustomFields &&
                    selectedProgram.programGroup.productCustomFields.length >= 10
                "
              >
                <b>{{ selectedProgram.programGroup.productCustomFields[9].name }}</b
                >:
                {{
                  saleProduct.product.customFieldValue10
                    | customField(selectedProgram.programGroup.productCustomFields[9])
                }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 1
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[0].name }}</b
                >: {{ saleProduct.customFieldValue1 | customField(selectedProgram.saleProductCustomFields[0]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 2
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[1].name }}</b
                >: {{ saleProduct.customFieldValue2 | customField(selectedProgram.saleProductCustomFields[1]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 3
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[2].name }}</b
                >: {{ saleProduct.customFieldValue3 | customField(selectedProgram.saleProductCustomFields[2]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 4
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[3].name }}</b
                >: {{ saleProduct.customFieldValue4 | customField(selectedProgram.saleProductCustomFields[3]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 5
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[4].name }}</b
                >: {{ saleProduct.customFieldValue5 | customField(selectedProgram.saleProductCustomFields[4]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 6
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[5].name }}</b
                >: {{ saleProduct.customFieldValue6 | customField(selectedProgram.saleProductCustomFields[5]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 7
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[6].name }}</b
                >: {{ saleProduct.customFieldValue7 | customField(selectedProgram.saleProductCustomFields[6]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 8
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[7].name }}</b
                >: {{ saleProduct.customFieldValue8 | customField(selectedProgram.saleProductCustomFields[7]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 9
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[8].name }}</b
                >: {{ saleProduct.customFieldValue9 | customField(selectedProgram.saleProductCustomFields[8]) }}<br />
              </li>
              <li
                v-if="
                  saleProduct &&
                    selectedProgram.saleProductCustomFields &&
                    selectedProgram.saleProductCustomFields.length >= 10
                "
              >
                <b>{{ selectedProgram.saleProductCustomFields[9].name }}</b
                >: {{ saleProduct.customFieldValue10 | customField(selectedProgram.saleProductCustomFields[9]) }}<br />
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApprovalSidebar",
  props: {
    approval: Object,
    approvalStage: Object,
    approvalType: Object,
    assignedToParticipant: Object,
    approvedUser: Object,
    rejectedUser: Object,
    submittedByParticipant: Object,
    payout: Object,
    sale: Object,
    saleType: Object,
    saleProducts: Array,
    saleParticipant: Object,
    saleOrganization: Object
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"])
  }
};
</script>
