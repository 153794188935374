<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Adjustments
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Single Adjustment") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Bulk Adjustments") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Upload History") }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <SingleAdjustment />
        </v-tab-item>
        <v-tab-item>
          <BulkAdjustment />
        </v-tab-item>
        <v-tab-item>
          <BulkAdjustmentHistory />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import BulkAdjustmentHistory from "./BulkAdjustmentHistory.vue";
import BulkAdjustment from "./BulkAdjustment.vue";
import SingleAdjustment from "./SingleAdjustment.vue";
export default {
  components: { SingleAdjustment, BulkAdjustment, BulkAdjustmentHistory },
  name: "Adjustments",
  metaInfo: {
    title: "Adjustments"
  },
  data: () => ({
    tab: null
  })
};
</script>
