<template>
  <v-container v-if="payout">
    <v-row>
      <v-col lg="8" md="8" sm="10" xs="12">
        <h2>Success!</h2>
        <p class="mt-3 mb-10">An Adjustment <b>payout</b> has been created.</p>
        <v-btn :loading="loading" color="primary" @click="onStartOver">
          Create Another Adjustment
        </v-btn>
      </v-col>
      <v-col cols="4">
        <ApprovalSidebar
          v-if="payout"
          :approval="approval"
          :payout="payout"
          :sale="selectedSale"
          :saleProducts="[selectedSaleProduct]"
          :saleParticipant="selectedParticipant"
          :saleOrganization="selectedOrganization"
        />
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <ApiError :errors="errors"></ApiError>
    <p>
      Please use the following form to issue an Adjustment for a {{ $i18n.translate("Participant") }} or
      {{ $i18n.translate("Organization") }}. All required fields are indicated with a <span class="red--text">*</span>.
    </p>
    <v-form ref="adjustmentLookupForm" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-radio-group outlined class="mt-0" v-model="assignmentValue" :rules="rules.assignment">
              <template v-slot:label>
                Is this adjustment for a {{ $i18n.translate("Participant") }} or
                {{ $i18n.translate("Organization") }}?<span class="red--text">*</span>
              </template>
              <v-radio :label="$i18n.translate('Participant')" value="PARTICIPANT" />
              <v-radio :label="$i18n.translate('Organization')" value="ORGANIZATION" />
            </v-radio-group>

            <ParticipantField
              outlined
              dense
              showKey
              v-if="assignmentValue === 'PARTICIPANT'"
              :label="'Select a ' + $i18n.translate('Participant')"
              v-model="selectedParticipant"
              :rules="rules.assignment"
              class="required"
              @change="fetchAvailableAwardVehicles"
            />
            <OrganizationField
              v-else-if="assignmentValue === 'ORGANIZATION'"
              outlined
              dense
              showKey
              :label="'Select a ' + $i18n.translate('Organization')"
              v-model="selectedOrganization"
              :rules="rules.assignment"
              class="required"
              @change="fetchAvailableAwardVehicles"
            />

            <AwardVehicleField
              v-if="selectedParticipant?.participantType || selectedOrganization?.organizationType"
              outlined
              dense
              id="availableAwardVehicles"
              name="availableAwardVehicles"
              :label="'Select an ' + $i18n.translate('Award Vehicle')"
              v-model="selectedAwardVehicle"
              :rules="rules.awardVehicle"
              :available="availableAwardVehicles"
              class="required"
            ></AwardVehicleField>

            <v-text-field
              v-if="(selectedParticipant || selectedOrganization) && selectedAwardVehicle"
              outlined
              dense
              ref="payoutAmount"
              id="payoutAmount"
              name="payoutAmount"
              :label="'Enter an Adjustment ' + $i18n.translate('Amount') + ' ' + minimumAndMaximumsText()"
              type="number"
              v-model="form.payoutAmount"
              :min="selectedAwardVehicle?.minimumPayoutAmount || 0"
              :max="selectedAwardVehicle?.maximumPayoutAmount || 99999"
              :rules="rules.payoutAmount"
              :error-messages="$error.getValidationError(errors, 'payoutAmount')"
              @input="$error.clearValidationError(errors, 'payoutAmount')"
              :disabled="!selectedAwardVehicle"
              :hint="!selectedAwardVehicle ? 'Need to define an award vehicle first.' : ''"
              persistent-hint
              class="required"
            />
            <v-select
              v-if="(selectedParticipant || selectedOrganization) && selectedAwardVehicle"
              outlined
              dense
              :items="[
                {
                  text: 'Use Award Vehicle Settings (' + (selectedAwardVehicle.taxable ? 'Yes' : 'No') + ')',
                  value: null
                },
                { text: 'Yes (Override Award Vehicle Settings)', value: true },
                { text: 'No (Override Award Vehicle Settings)', value: false }
              ]"
              v-model="form.taxable"
              label="Is this Adjustment Taxable?"
              class="required"
            ></v-select>

            <v-textarea
              v-if="(selectedParticipant || selectedOrganization) && selectedAwardVehicle"
              outlined
              dense
              rows="3"
              name="comment"
              label="Enter an Adjustment Message or Comment"
              id="comment"
              class="required"
              v-model="form.comment"
              :rules="rules.comment"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div v-if="(selectedParticipant || selectedOrganization) && selectedAwardVehicle">
              <h3>Optional Fields</h3>
              <p>
                The following fields are optional and can be provided for reporting purposes.
              </p>

              <PromotionSimpleField
                outlined
                dense
                :label="'Select a ' + $i18n.translate('Promotion')"
                :showKey="true"
                v-model="selectedPromotion"
                :status="'All'"
              />

              <SaleField
                outlined
                dense
                :label="'Select a ' + $i18n.translate('Sale')"
                :showKey="true"
                v-model="selectedSale"
                :status="'All'"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue1"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 1"
                :customField="selectedProgram.payoutCustomFields[0]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue2"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 2"
                :customField="selectedProgram.payoutCustomFields[1]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue3"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 3"
                :customField="selectedProgram.payoutCustomFields[2]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue4"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 4"
                :customField="selectedProgram.payoutCustomFields[3]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue5"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 5"
                :customField="selectedProgram.payoutCustomFields[4]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue6"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 6"
                :customField="selectedProgram.payoutCustomFields[5]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue7"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 7"
                :customField="selectedProgram.payoutCustomFields[6]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue8"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 8"
                :customField="selectedProgram.payoutCustomFields[7]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue9"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 9"
                :customField="selectedProgram.payoutCustomFields[8]"
              />

              <CustomField
                outlined
                dense
                v-model="form.customFieldValue10"
                v-if="selectedProgram.payoutCustomFields && selectedProgram.payoutCustomFields.length >= 10"
                :customField="selectedProgram.payoutCustomFields[9]"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="mt-3" :loading="loading" color="primary" :disabled="!valid" @click="onSubmit">
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import AwardVehicleField from "@/gapp-components/components/fields/AwardVehicleField.vue";
import OrganizationField from "@/gapp-components/components/fields/OrganizationField.vue";
import ParticipantField from "@/gapp-components/components/fields/ParticipantField.vue";
import PromotionSimpleField from "@/gapp-components/components/fields/PromotionSimpleField.vue";
import { mapGetters } from "vuex";
import ValidationService from "../../../services/validation.service";
import CustomField from "../../fields/CustomField.vue";
import SaleField from "../../fields/SaleField.vue";
import ApprovalSidebar from "../promotions/ApprovalSidebar.vue";

export default {
  components: {
    ApiError,
    ParticipantField,
    PromotionSimpleField,
    OrganizationField,
    ApprovalSidebar,
    AwardVehicleField,
    SaleField,
    CustomField
  },
  name: "SingleAdjustment",
  data: () => ({
    tab: null,
    step: "1",
    valid: false,
    loading: false,
    errors: {},
    assignmentValue: null,
    form: {
      taxable: null,
      payoutAmount: 0
    },

    saleType: null,
    sale: null,
    saleProducts: [],
    saleProduct: null,
    approvalType: null,
    approval: null,

    payout: null,

    selectedPromotion: null,
    selectedSale: null,
    selectedSaleProduct: null,
    selectedParticipant: null,
    selectedOrganization: null,
    selectedAwardVehicle: null,
    availableAwardVehicles: []
  }),

  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedLocale"]),

    rules() {
      return {
        assignment: [
          ValidationService.generateNotWhitespacesRule("assignment"),
          ...ValidationService.generateDropdownValidationRules("assignment")
        ],
        awardVehicle: ValidationService.generateDropdownValidationRules("award vehicle"),
        comment: ValidationService.generateTextValidationRules("message or comment", 255),
        promotion: [v => (!!v && v.id > 0) || "A promotion is required."],
        payoutAmount: [
          v => !!v || "A payout amount is required.",
          v =>
            v >= (this.selectedAwardVehicle?.minimumPayoutAmount || 0) ||
            `Amount must be at least ${this.selectedAwardVehicle?.minimumPayoutAmount}`,
          v =>
            v <= (this.selectedAwardVehicle?.maximumPayoutAmount || 99999) ||
            `Amount must be less than ${this.selectedAwardVehicle?.maximumPayoutAmount ?? 99999}`
        ],
        participant: [v => (!!v && v.id > 0) || "A payout assignment is required."],
        organization: [v => (!!v && v.id > 0) || "A payout assignment is required."]
      };
    }
  },

  methods: {
    minimumAndMaximumsText() {
      let text = "";
      if (this.selectedAwardVehicle) {
        if (this.selectedAwardVehicle.minimumPayoutAmount && this.selectedAwardVehicle.maximumPayoutAmount) {
          text =
            "(min: " +
            this.selectedAwardVehicle?.minimumPayoutAmount +
            ", max: " +
            this.selectedAwardVehicle.maximumPayoutAmount +
            ")";
        } else if (this.selectedAwardVehicle.minimumPayoutAmount) {
          text = "(min: " + this.selectedAwardVehicle.minimumPayoutAmount + ")";
        } else if (this.selectedAwardVehicle.maximumPayoutAmount) {
          text = "(max: " + this.selectedAwardVehicle.maximumPayoutAmount + ")";
        }
      }
      return text;
    },
    onStartOver() {
      this.payout = null;
      this.selectedSale = null;
      this.selectedSaleProduct = null;
      this.selectedPromotion = null;
      this.selectedAwardVehicle = null;
      this.selectedParticipant = null;
      this.selectedOrganization = null;
      this.form = {
        taxable: null,
        payoutAmount: 0
      };
    },
    fetchSelectedSaleProducts(sale) {
      if (this.selectedSale) {
        return this.$api.get("/api/saleProducts/bySaleId/" + this.selectedSale.id + "?size=1").then(({ data }) => {
          if (data.content && data.content.length > 0) {
            this.selectedSaleProduct = data.content[0];
            return Promise.resolve();
          } else {
            return Promise.reject("Unable to find sale product for Sale " + sale.saleKey);
          }
        });
      } else {
        return Promise.resolve();
      }
    },
    onSubmit() {
      this.errors = {};
      this.loading = true;

      return this.fetchSelectedSaleProducts()
        .then(() => {
          return this.createPayout();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createPayout() {
      let payout = {
        awardVehicleDetermination: { name: "DETERMINED_BY_MANUAL" },
        awardVehicle: { id: this.selectedAwardVehicle?.id },
        currency: { name: this.selectedAwardVehicle?.currency?.name },
        promotion: this.selectedPromotion ? { id: this.selectedPromotion.id } : null,
        payoutStrategyType: this.selectedPromotion ? { name: this.selectedPromotion.payoutStrategyType?.name } : null,
        sale: this.selectedSale ? { id: this.selectedSale.id } : null,
        saleProduct: this.selectedSaleProduct ? { id: this.selectedSaleProduct.id } : null,
        payoutAmount: parseFloat(this.form.payoutAmount),
        payoutType: { name: "MONEY" },
        payoutMessage: this.form.comment || ""
      };
      if (this.form.taxable != null) {
        payout.taxable = this.form.taxable;
        payout.taxableDetermination = { name: "DETERMINED_BY_PAYOUT" };
      }
      if (this.form.customFieldValue1 != null) {
        payout.customFieldValue1 = this.form.customFieldValue1;
      }
      if (this.form.customFieldValue2 != null) {
        payout.customFieldValue2 = this.form.customFieldValue2;
      }
      if (this.form.customFieldValue3 != null) {
        payout.customFieldValue3 = this.form.customFieldValue3;
      }
      if (this.form.customFieldValue4 != null) {
        payout.customFieldValue4 = this.form.customFieldValue4;
      }
      if (this.form.customFieldValue5 != null) {
        payout.customFieldValue5 = this.form.customFieldValue5;
      }
      if (this.form.customFieldValue6 != null) {
        payout.customFieldValue6 = this.form.customFieldValue6;
      }
      if (this.form.customFieldValue7 != null) {
        payout.customFieldValue7 = this.form.customFieldValue7;
      }
      if (this.form.customFieldValue8 != null) {
        payout.customFieldValue8 = this.form.customFieldValue8;
      }
      if (this.form.customFieldValue9 != null) {
        payout.customFieldValue9 = this.form.customFieldValue9;
      }
      if (this.form.customFieldValue10 != null) {
        payout.customFieldValue10 = this.form.customFieldValue10;
      }

      if (this.selectedOrganization) {
        payout.organization = { id: this.selectedOrganization.id };
      } else if (this.selectedParticipant) {
        payout.participant = { id: this.selectedParticipant.id };
      } else {
        return Promise.reject("Assignment of adjustment is missing");
      }

      return this.$api.post("/api/payouts/create", payout).then(({ data }) => {
        this.payout = data;
      });
    },
    fetchAvailableAwardVehicles() {
      this.selectedAwardVehicle = null;
      if (this.selectedParticipant) {
        return this.$awardvehicle
          .getAvailableAwardVehicles(this.selectedParticipant, null, this.selectedPromotion)
          .then(avs => {
            this.availableAwardVehicles = avs;
          });
      } else if (this.selectedOrganization) {
        return this.$awardvehicle
          .getAvailableAwardVehicles(null, this.selectedOrganization, this.selectedPromotion)
          .then(avs => {
            this.availableAwardVehicles = avs;
          });
      } else {
        return Promise.resolve();
      }
    }
  },

  watch: {
    selectedAwardVehicle(v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs.payoutAmount.validate();
        });
      }
    },
    assignmentValue(newValue, oldValue) {
      if (newValue !== null && newValue !== oldValue) {
        this.onStartOver();
      }
    }
  }
};
</script>
