<template>
  <v-card flat>
    <v-toolbar flat>
      <v-toolbar-title class="headline">
        Adjustment Details
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Adjustment')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
    </v-toolbar>

    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <v-data-table :headers="headers" :items="items" :server-items-length="total" :options.sync="options">
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" class="ma-2" text-color="black">{{ item.status }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "../../../services/api.service";
import ApiError from "../../display/ApiError.vue";

export default {
  name: "BulkAdjustmentDetail",
  components: {
    ApiError
  },
  props: {
    batchId: String
  },
  data() {
    return {
      search: "",

      errors: {},

      headers: [
        { text: "ID", value: "id" },
        { text: "SalesPerson", value: "participantKey" },
        { text: "Dealer", value: "organizationKey" },
        { text: "Adjustment Message", value: "payoutMessage" },
        { text: "Amount", value: "payoutAmount" },
        { text: "Status", value: "status" },
        { text: "Error", value: "errorMessage" }
      ],

      items: [],

      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["createdDate"],
        sortDesc: [true]
      },
      total: 0
    };
  },
  created() {
    this.getData();
  },
  watch: {
    batchId() {
      this.getData();
    }
  },
  methods: {
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },

    onClear() {
      this.search = "";
      this.getData();
    },

    async getData() {
      try {
        const { itemsPerPage, page, sortBy, sortDesc } = this.options;
        const queryParams =
          `?size=${itemsPerPage}&page=${page - 1}` +
          (sortBy && sortBy.length > 0
            ? `&sort=${sortBy[0]},${sortDesc && sortDesc.length > 0 && sortDesc[0] ? "DESC" : "ASC"}`
            : "");

        let filters = {};
        filters.batchId = this.batchId;
        if (this.search && this.search.length > 0) {
          filters.keyword = this.search;
        }
        const { data: response } = await ApiService.post(`/api/payoutLoadBatches/search${queryParams}`, filters);
        this.items = response.content;
        this.total = response.totalElements;
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "FAILED":
          return "red";
        case "COMPLETED":
          return "green";
      }
    }
  }
};
</script>
